@font-face {
  font-family: Wardrum-Outline;
  src: url("./assets/fonts/Wardrum-Outline.ttf");
}

@font-face {
  font-family: Wardrum;
  src: url("./assets/fonts/Wardrum-Regular.ttf");
}

.App {
  text-align: center;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-text {
  font: 50px Wardrum-Outline;
  width: 200px;
  height: 50px;
}

.logo-text text {
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linejoin: round;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sidebar-toggle-button {
  position: absolute;
}

@keyframes changeColor {
  0% {
    background-color: #4ade80; /* Start color (green) */
    background-size: 100% 0%; /* Start with no height */
    background-position: 0% 0%; /* Start from the top */
  }
  100% {
    background-color: #ff0000; /* End color (red) */
    background-size: 100% 100%; /* End with full height */
    background-position: 0% 100%; /* End at the bottom */
  }
}

@keyframes reverseChangeColor {
  0% {
    background-color: #ff0000; /* Start color (green) */
    background-size: 100% 0%; /* Start with no height */
    background-position: 0% 0%; /* Start from the top */
  }
  100% {
    background-color: #00ff00; /* End color (red) */
    background-size: 100% 100%; /* End with full height */
    background-position: 0% 100%; /* End at the bottom */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
|======================================================
| Bootstrap Form
|======================================================
*/
.form-group {
  @apply mb-4;
}

.form-label {
  @apply block mb-1;
}

.form-control {
  @apply w-full p-2 border border-gray-300 rounded;
}

.form-textarea {
  @apply w-full p-2 border border-gray-300 rounded;
}

.form-select {
  @apply w-full p-2 border border-gray-300 rounded bg-white;
}

.form-check {
  @apply flex items-center mb-2;
}

.form-check-input {
  @apply h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500;
}

.form-check-label {
  @apply ml-2 text-sm text-gray-700;
}

.form-radio {
  @apply flex items-center mb-2;
}

.form-radio-input {
  @apply h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500;
}

.form-radio-label {
  @apply ml-2 text-sm text-gray-700;
}

.form-error {
  @apply text-red-500 text-sm mt-1;
}

label.required::after {
  content: "*";
  color: #f87171; /* Tailwind's red-400 color */
  margin-left: 0.25rem; /* Optional: Add some space between text and asterisk */
}

/*
|======================================================
| Bootstrap Buttons
|======================================================
*/
.btn-primary {
  @apply bg-primary text-white py-2 px-4 rounded hover:bg-blue-600 inline-flex items-center;
}

.btn-secondary {
  @apply bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 inline-flex items-center;
}

.btn-success {
  @apply bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 inline-flex items-center;
}

.btn-danger {
  @apply bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 inline-flex items-center;
}

.btn-warning {
  @apply bg-yellow-500 text-black py-2 px-4 rounded hover:bg-yellow-600 inline-flex items-center;
}

.btn-info {
  @apply bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-600 inline-flex items-center;
}

.btn-light {
  @apply bg-gray-200 text-black py-2 px-4 rounded hover:bg-gray-300 inline-flex items-center;
}

.btn-dark {
  @apply bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-800 inline-flex items-center;
} 


/*
|======================================================
| Pagination
|======================================================
*/
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  font-size: 14px;
  color: #555;
}

.pagination-buttons {
  display: flex;
  gap: 4px;
}

.pagination-button {
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #475569;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  cursor: pointer;
}

.pagination-button:hover {
  color: #fff;
  background-color: #1e293b;
  border-color: #1e293b;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pagination-button:focus,
.pagination-button:active {
  color: #fff;
  background-color: #1e293b;
  border-color: #1e293b;
  box-shadow: none;
}

.pagination-button:disabled {
  pointer-events: none;
  opacity: 0.5;
  box-shadow: none;
}

.pagination-button.active {
  font-weight: bold;
  color: #fff;
  background-color: #1e293b;
  border-color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
